import Header from "components/Header";
import Hero from "components/Main/Hero";
import Map from "components/Main/Map";
import CountryComparison from "components/Main/CountryComparison";
import AboutUs from "components/Main/AboutUs";
import WorldDataPro from "components/Main/WorldDataPro";
import MobileModal from "components/MobileModal";
import Footer from "components/Footer";
import YearSlider from "components/YearSlider";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import FixedBanner from "components/FixedBanner";
const CategoriesClock = () => (
    <>
        <Header />
        <main tw="relative w-full">
            <FixedBanner/>
            <Hero />
            <YearSlider />
            <Map />
            <CountryComparison />
            <AboutUs />
            <WorldDataPro />
            <MobileModal />
        </main>
        <Footer />
    </>
);

export default CategoriesClock;

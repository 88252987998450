import { useCategoriesClock } from "context/CategoriesClock";
import { t } from "i18next";
import { handleOrdinal } from "utils/utilities";
import {
  CountryDataTypes,
  HighSpendingCount,
  SpendingData,
} from "types/data.types";
import { getCountryInfo, handleTitle } from "utils/countries";
import Loader from "components/Loader";
import Continents from "components/Main/Map/Card/Continents";
import { continentsList, handleCategoriesIcon } from "utils/list";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { useEffect, useMemo, useState } from "react";
import ResponsiveImage from "components/ResponsiveImagePC";

const Table = ({
  data,
  spendingLevelData,
  dataForContinents,
  isLoading,
}: {
  data: CountryDataTypes;
  spendingLevelData: any;
  dataForContinents: any;
  isLoading: boolean;
}) => {
  const { countries, countryOverview, countryNational, isMapViewActive } =
    useCategoriesClock();
  const [filteredData, setFilteredData] = useState<any>(null);
  const countryId = getCountryInfo(countries.mapCountry, true)?.id;

  const mergedData = useMemo(
    () =>
      dataForContinents?.map((country: any) => {
        const matchedCountry = spendingLevelData?.find(
          ({ id }: { id: string }) => id === country.code
        );

        return { ...matchedCountry, ...country };
      }),
    [dataForContinents, spendingLevelData]
  );

  const filterByActiveCountryContinent = () =>
    mergedData
      ?.map((country: any) => {
        const matchedContinent = continentsList.find(
          ({ id }) => id === country.code
        );

        return {
          continent: country.continent,
          name: country.name,
          file_url: country.file_url,
          id: country.id,
          spendingLevel: country.spendingLevel,
          ...matchedContinent,
        };
      })
      .reduce((acc: any, item: any) => {
        if (item.continent == null) return acc;
        if (!acc[item.continent]) acc[item.continent] = [];
        acc[item.continent].push(item);
        return acc;
      }, {});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDisplayData = (filtered: any) => {
    if (isMapViewActive)
      return Object.keys(filtered)
        .map((continent) => {
          const countries = filtered[continent];
          const countryMatch = countries.find(
            (country: SpendingData) => country.id === countryId
          );

          if (countryMatch) return { countries, continent };

          return null;
        })
        .filter((item) => item !== null);
    else {
      const highSpendingCounts: HighSpendingCount[] = Object.keys(filtered).map(
        (continent) => {
          const highSpendingCount = filtered[continent].filter(
            (item: SpendingData) => item.spendingLevel === "HIGH_SPENDING"
          ).length;
          return { continent, count: highSpendingCount };
        }
      );

      highSpendingCounts.sort((a: any, b: any) => b.count - a.count);

      return highSpendingCounts;
    }
  };

  useEffect(() => {
    const filtered = filterByActiveCountryContinent();
    if (filtered) {
      const data = handleDisplayData(filtered);
      setFilteredData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergedData, isMapViewActive, countryId]);

  return (
    <article
      css={[
        tw`font-semiBold text-blue-800 relative`,
        countryOverview === "overview" && tw`text-center`,
      ]}>
      <h3>
        {handleTitle(countryOverview, countryNational)}{" "}
        {countryNational === "CONTINENTAL" &&
          t(isMapViewActive ? filteredData[0].continent : "world")}
      </h3>
      {countryOverview === "overview" ? (
        <div tw="flex flex-col items-center mb-20 mt-1 lg:mb-0 max-w-max p-4 pt-2 gap-4">
          <a
            tw="py-1 px-4 rounded-[6.25rem] border border-blue-800 hover:bg-blue-800 hover:text-white"
            href="https://dashboard.worlddata.pro/freemium/register">
            {t("meet_wdp")}
          </a>
          <ResponsiveImage />
          <p tw="text-left font-regular">{t("meet_wdp_description")}</p>
        </div>
      ) : (
        <ul
          className="scrollable"
          tw="m-[1rem 0 5rem] lg:m-[1rem 0 0] h-[23rem] overflow-auto p-[0 1.5rem 0 0.8rem] border border-blue-800 rounded bg-white">
          {isLoading ? (
            <Loader />
          ) : countryNational === "CONTINENTAL" ? (
            !filteredData ? (
              <Loader />
            ) : (
              <Continents
                data={
                  isMapViewActive ? filteredData[0].countries : filteredData
                }
              />
            )
          ) : (
            data.map(({ rank, code, name }) => (
              <li
                key={code}
                tw="flex flex-row justify-start items-center gap-[1rem] border-b border-b-grey-100 py-[1.5rem] font-semiBold text-lg md:text-sm">
                <span tw="w-[4.5rem]">
                  {rank}
                  <sup>{handleOrdinal(rank)}</sup>
                </span>
                <div tw="relative w-[3rem]">
                  {(!isMapViewActive ||
                    (isMapViewActive && countryOverview === "spending")) && (
                    <img
                      src={getCountryInfo(code, false)?.file_url}
                      alt={name}
                    />
                  )}
                  {isMapViewActive && countryOverview !== "spending" && (
                    <img src={handleCategoriesIcon(code)} alt={name} />
                  )}
                </div>
                <div tw="relative w-full">
                  {!isMapViewActive ||
                  (isMapViewActive && countryOverview === "spending")
                    ? getCountryInfo(code, false)?.name
                    : name}
                </div>
              </li>
            ))
          )}
        </ul>
      )}
    </article>
  );
};

export default Table;

import { t } from "i18next";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
import { mapCategoriesLists } from "utils/list";

/** @jsxImportSource @emotion/react */
import "twin.macro";
import { useCategoriesClock } from "context/CategoriesClock";

const Mosaic = () => {
    const { setFilters } = useCategoriesClock();

    return (
        <div tw="grid grid-cols-[repeat(auto-fill, minmax(160px, 1fr))] w-full">
            {mapCategoriesLists.map(({ name, id }) => (
                <div
                    key={id}
                    tw="w-full h-[160px] odd-of-type:bg-blue-100 even-of-type:bg-green-0"
                    className="cards"
                >
                    <button
                        tw="p-2 w-full h-full flex flex-col justify-center items-center"
                        onClick={() => {
                            setFilters({
                                name: "category",
                                value: name
                            });
                        }}
                    >
                        <img
                            src={`assets/images/${name}.svg`}
                            alt={name}
                            width={70}
                        />
                        <p tw="hidden md:block text-blue-800 font-medium text-[0.9rem] md:text-xs">
                            {t(name)}
                        </p>
                    </button>
                    <button
                        tw="w-full h-full p-2 items-center justify-center flex-col gap-4 relative top-[-100%] hidden [.cards:hover &]:(flex bg-blue-700 md:hidden) [.cards:hover:nth-of-type(even) &]:bg-green-400"
                        onClick={() => {
                            setFilters({
                                name: "category",
                                value: name
                            });
                        }}
                    >
                        <img
                            src={`assets/images/${name}.svg`}
                            alt={name}
                            tw="block mx-auto mt-[-0.5rem] [.cards:hover:nth-of-type(even) &]:hidden [.cards:hover &]:opacity-70"
                        />
                        <h3 tw="font-semiBold text-center absolute top-6 right-2 left-2 [.cards:hover &]:text-white [.cards:hover:nth-of-type(even) &]:text-blue-800">
                            {t(name)}
                        </h3>
                        <span
                            tw="text-[0.9rem] flex items-center justify-center gap-2 font-medium absolute bottom-3 w-full [.cards:hover &]:text-green-400 [.cards:hover:nth-of-type(even) &]:text-blue-500"
                            className="explore"
                        >
                            {t("explore")}
                            <DynamicSvg iconName="exploreArrow" />
                        </span>
                    </button>
                </div>
            ))}
        </div>
    );
};

export default Mosaic;

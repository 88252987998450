import { t } from "i18next";
import tw from "twin.macro";
import Logo from "assets/images/bannerLogo.svg";
import Close from "assets/images/bannerClose.svg";
import Link from "assets/images/bannerLink.svg";
import Chevron from "assets/images/bannerChevron.svg";
import { bannerStyle, bannerStyle2 } from "utils/utilities";
/** @jsxImportSource @emotion/react */
import { useState } from "react";

const FixedBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  return (
    <aside style={isBannerVisible ? bannerStyle : bannerStyle2} tw="fixed">
      {isBannerVisible ? (
        <div tw="py-5 gap-[0.313rem] grid justify-items-center">
          <div tw="w-6 h-[1.161rem]">
            <img src={Logo} alt="Logo" />
          </div>
          <p tw="text-center text-xl font-semiBold text-white px-[3.125rem] leading-[1.5rem]">
            {t("sticky_banner")}
          </p>
          <a
            href="https://dashboard.worlddata.pro/freemium/register"
            target="_blank"
            rel="noopener noreferrer"
            tw="flex flex-row justify-center pt-2.5 gap-4 w-[12.688rem] h-10
                            rounded-full border text-sm font-semiBold ">
            {t("sign_up")}
            <img src={Link} tw="pb-1 w-6 h-6" alt="Link Icon" />
          </a>
          <div tw="absolute right-2 top-2 w-11 h-11 cursor-pointer">
            <img
              onClick={() => setIsBannerVisible(false)}
              src={Close}
              alt="Close Icon"
            />
          </div>
        </div>
      ) : (
        <div
          tw="h-[11.25rem] justify-center items-center flex flex-col cursor-pointer w-[1.5rem] hover:w-[1.875rem]"
          onClick={() => setIsBannerVisible(true)}>
          <img tw="w-6 h-6" src={Chevron} alt="Chevron" />
        </div>
      )}
    </aside>
  );
};

export default FixedBanner;

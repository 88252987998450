import useFetch from "hooks/useFetch";
import { getCategoryCode } from "utils/utilities";
import { urls } from "config/urls";
import { CountryDataTypes } from "types/data.types";
import { t } from "i18next";
import Actions from "components/Main/Map/Card/Actions";
import Table from "components/Main/Map/Card/Table";
import { useCategoriesClock } from "context/CategoriesClock";
import back from "assets/images/map/back.svg";
import { getCountryInfo } from "utils/countries";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Card = ({ spendingLevelData }: { spendingLevelData: any }) => {
    const {
        category,
        countryNational,
        countryOverview,
        countries,
        measure,
        setCountryFilters,
        setFilters,
        year
    } = useCategoriesClock();

    const { data, isLoading }: { data: CountryDataTypes; isLoading: boolean } =
        useFetch(
            `${urls.API_HOST_URL}countries/${
                getCountryInfo(countries.mapCountry, true)?.iso3c
            }/ranking/${
                countryOverview === "category" ? "category" : "spending"
            }?year=${year}&measure=${measure}&mapTypeFilter=${
                countryNational === "CONTINENTAL" ? "GLOBAL" : countryNational
            }${
                category !== "all_categories" && countryOverview === "spending"
                    ? `&categoryCode=${getCategoryCode(category)}`
                    : ""
            }`
        );

    const { data: dataForContinents }: { data: CountryDataTypes } = useFetch(
        `${urls.API_HOST_URL}countries/${
            getCountryInfo(countries.mapCountry, true)?.iso3c
        }/ranking/spending?year=${year}&measure=${measure}&mapTypeFilter=${
            countryNational === "CONTINENTAL" ? "GLOBAL" : countryNational
        }${
            category !== "all_categories" && countryOverview === "spending"
                ? `&categoryCode=${getCategoryCode(category)}`
                : ""
        }`
    );

    return (
        <article tw="flex flex-1 h-[calc(100% - 5rem)] lg:h-full lg:col-span-3 flex-col bg-green-0 p-[2rem 1rem] gap-4">
            <div tw="text-blue-800 font-semiBold text-xl flex items-start justify-between w-full md:items-center">
                <h3>
                    {category !== "all_category" &&
                    countryOverview === "spending"
                        ? t(category)
                        : countries.mapCountry}
                </h3>
                <button
                    tw="p-4 border border-blue-800 rounded-full"
                    onClick={() => {
                        setCountryFilters({ name: "mapCountry", value: "" });
                        setFilters({
                            name: "category",
                            value: "all_categories"
                        });
                        setFilters({
                            name: "countryLevel",
                            value: null
                        });
                        setFilters({
                            name: "countryNational",
                            value: "GLOBAL"
                        });
                    }}
                >
                    <img alt="Back" src={back} />
                </button>
            </div>
            <Actions />
            <Table
                data={data}
                spendingLevelData={spendingLevelData}
                dataForContinents={dataForContinents}
                isLoading={isLoading}
            />
        </article>
    );
};

export default Card;

import { t } from "i18next";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
import { useCategoriesClock } from "context/CategoriesClock";
import { getCountryInfo } from "utils/countries";
import { CountryComparisonDataTypes } from "types/data.types";
import { urls } from "config/urls";
import useFetch from "hooks/useFetch";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Population = () => {
    const { year, countries } = useCategoriesClock();

    const {
        data: firstComparedCountryData,
        isLoading: isFirstComparedCountryDataLoading
    }: { data: CountryComparisonDataTypes; isLoading: boolean } = useFetch(
        `${urls.API_HOST_URL}countries/${
            getCountryInfo(countries.comparedFirst, true)?.iso3c
        }?year=${year}`,
        countries.comparedFirst === "" || countries.comparedFirst === null
    );

    const {
        data: secondComparedCountryData,
        isLoading: isSecondComparedCountryDataLoading
    }: { data: CountryComparisonDataTypes; isLoading: boolean } = useFetch(
        `${urls.API_HOST_URL}countries/${
            getCountryInfo(countries.comparedSecond, true)?.iso3c
        }?year=${year}`,
        countries.comparedSecond === "" || countries.comparedSecond === null
    );

    const {
        data: thirdComparedCountryData,
        isLoading: isThirdComparedCountryDataLoading
    }: { data: CountryComparisonDataTypes; isLoading: boolean } = useFetch(
        `${urls.API_HOST_URL}countries/${
            getCountryInfo(countries.comparedLast, true)?.iso3c
        }?year=${year}`,
        countries.comparedLast === "" || countries.comparedLast === null
    );

    return (
        <div tw="grid grid-cols-4 text-center font-semiBold">
            <div tw="px-[1rem] flex items-center font-regular gap-5 border-r border-r-grey-200">
                <div tw="md:block hidden">
                    <DynamicSvg size={24} iconName="totalPopulation" />
                </div>
                <h4>{t("country_comparison.total_population")}</h4>
            </div>
            <div tw="flex flex-col justify-center border-r border-r-grey-200 p-[0.75rem] h-auto min-h-[4.5rem]">
                {countries.comparedFirst === null ||
                isFirstComparedCountryDataLoading ? null : (
                    <>
                        <img
                            src={
                                getCountryInfo(countries.comparedFirst, true)
                                    ?.file_url
                            }
                            alt="Flag"
                            tw="hidden md:block m-auto"
                            width={24}
                        />
                        {firstComparedCountryData?.population.toLocaleString()}
                    </>
                )}
            </div>
            <div tw="flex flex-col justify-center border-r border-r-grey-200 p-[0.75rem] h-auto min-h-[4.5rem]">
                {countries.comparedSecond === null ||
                isSecondComparedCountryDataLoading ? null : (
                    <>
                        <img
                            src={
                                getCountryInfo(countries.comparedSecond, true)
                                    ?.file_url
                            }
                            alt="Flag"
                            tw="hidden md:block m-auto"
                            width={24}
                        />
                        {secondComparedCountryData?.population.toLocaleString()}
                    </>
                )}
            </div>
            <div tw="flex flex-col justify-center border-r border-r-grey-200 p-[0.75rem] h-auto min-h-[4.5rem]">
                {countries.comparedLast === null ||
                isThirdComparedCountryDataLoading ? null : (
                    <>
                        <img
                            src={
                                getCountryInfo(countries.comparedLast, true)
                                    ?.file_url
                            }
                            alt="Flag"
                            tw="hidden md:block m-auto"
                            width={24}
                        />
                        {thirdComparedCountryData?.population.toLocaleString()}
                    </>
                )}
            </div>
        </div>
    );
};

export default Population;

import { t } from "i18next";
import { getCountryInfo } from "utils/countries";
import { handleOrdinal } from "utils/utilities";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Continents = (data: any) =>
  data.data.map(
    (
      { id, name, continent }: { id: string; name: string; continent: string },
      i: number
    ) => (
      <li
        key={id}
        tw="flex justify-start items-center gap-[1.125rem] border-b border-b-grey-100 py-[1.5rem] font-semiBold text-lg md:text-sm">
        <span>
          {i + 1}
          <sup>{handleOrdinal(i + 1)}</sup>
        </span>
        {name && (
          <img
            width={38}
            src={getCountryInfo(id, false)?.file_url}
            alt={name}
          />
        )}
        {t(name ? name : continent)}
      </li>
    )
  );

export default Continents;

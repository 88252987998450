import Actions from "components/Main/AboutUs/Actions";
import About from "components/Main/AboutUs/About";
import Methodology from "components/Main/AboutUs/Methodology";
import wdl from "assets/images/wdl.svg";
import useToggle from "hooks/useToggle";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const AboutUs = () => {
    const { handleToggleState, toggleState } = useToggle();

    return (
        <section
            id="aboutUs"
            tw="bg-green-0 p-[4rem 4rem 4rem 0] lg:p-[4rem 1rem 4rem 0] md:p-[0 0 4.125rem 0] sm:p-[0 0 2rem 0] flex justify-center"
        >
            <div tw="max-w-[90rem] m-auto w-full">
                <div tw="flex justify-end mb-8 lg:hidden">
                    <Actions
                        handleToggleState={handleToggleState}
                        toggleState={toggleState}
                    />
                </div>
                <article tw="grid grid-cols-[50% 1fr] gap-20 lg:gap-8  items-center lg:flex-col lg:flex">
                    <img src={wdl} alt="Wdl" />
                    <div tw="hidden lg:flex w-[calc(100% - 2rem)] justify-center">
                        <Actions
                            handleToggleState={handleToggleState}
                            toggleState={toggleState}
                        />
                    </div>
                    {!toggleState.isAboutUsActive ? <About /> : <Methodology />}
                </article>
            </div>
        </section>
    );
};

export default AboutUs;

import useFetch from "hooks/useFetch";
import { urls } from "config/urls";
import { t } from "i18next";
import { useMemo } from "react";
import { useCategoriesClock } from "context/CategoriesClock";
import InputSelect from "components/Inputs/Select";
import FlipNumbers from "react-flip-numbers";
import useWindowResize from "hooks/useWindowResize";
import { HeroSectionDataTypes } from "types/data.types";
import {
  getCategoryCode,
  handleCurrentYear,
  roundToMillions,
} from "utils/utilities";
import useTicking from "hooks/useTicking";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

const Hero = () => {
  const { year, category } = useCategoriesClock();
  const { isDesktop, isTablet } = useWindowResize();

  const flipNumberStyles = useMemo(
    () => ({
      color: theme`colors.green.400`,
      fontSize: isDesktop ? "5rem" : isTablet ? "3.5rem" : "2rem",
      fontWeight: 800,
      fontFamily: "Epilogue",
      perspective: 100000,
    }),
    [isDesktop, isTablet]
  );

  const { data }: { data: HeroSectionDataTypes } = useFetch(
    `${urls.API_HOST_URL}main-hero?year=${year}${
      category !== "all_categories"
        ? `&categoryCode=${getCategoryCode(category)}`
        : ""
    }`
  );

  const { tickingValue } = useTicking(data?.alreadySpent);

  const categorySpendingValue = handleCurrentYear(year)
    ? tickingValue
    : data?.alreadySpent;

  return (
    <section
      tw="relative w-full h-[calc(100vh - 10.8rem)] md:h-[calc(100vh - 3.8rem)] text-center text-white"
      id="home">
      <article tw="w-full h-full flex flex-col justify-center p-4">
        {handleCurrentYear(year) ? (
          <h2 tw="absolute w-[calc(100% - 2rem)] top-8 font-medium text-[1.2rem] md:text-sm">
            {t("projected_spent_for")} {year}:{" "}
            <strong tw="text-green-400">
              ${" "}
              {`${roundToMillions(data?.projectedSpent)
                .toLocaleString("de-DE")
                .replaceAll(",", ".")}M`}
            </strong>
          </h2>
        ) : null}
        <div tw="flex flex-col text-[2.2rem] md:text-regular md:p-[1rem] md:gap-[1rem]">
          <p>
            {t("in")} {year},{" "}
            {!handleCurrentYear(year)
              ? t("the_world_will_spend")
              : t("the_world_has_spent")}
          </p>
          <strong tw="text-green-400 flex justify-center items-center gap-1">
            $
            <FlipNumbers
              height={isDesktop ? 100 : isTablet ? 65 : 35}
              width={isDesktop ? 55 : isTablet ? 36 : 18}
              color={theme`colors.green.400`}
              play
              perspective={flipNumberStyles.perspective}
              numbers={
                data?.alreadySpent
                  ? categorySpendingValue.toLocaleString().replaceAll(",", ".")
                  : ""
              }
              numberStyle={flipNumberStyles}
              nonNumberStyle={flipNumberStyles}
            />
            USD
          </strong>
          <div tw="grid grid-cols-[repeat(3, auto)] justify-center items-center gap-[1rem] md:gap-[0.5rem]">
            {t("with")}
            <InputSelect name="category" value={category} />
            {t("so_far")}
          </div>
        </div>
      </article>
      <video
        src={`assets/videos/${category}.mp4`}
        autoPlay
        loop
        muted
        playsInline
        tw="absolute top-0 left-0 z-[-200] h-full w-full object-cover"></video>
    </section>
  );
};

export default Hero;

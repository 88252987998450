import { useCategoriesClock } from "context/CategoriesClock";
import { t } from "i18next";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { years } from "utils/list";
import arrow from "assets/images/arrowDropdown.svg";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";

const StyledSlider = styled(Slider)(() => ({
  ".MuiSlider-mark": {
    borderRadius: 50,
    height: "0.3rem",
    width: "0.3rem",
    backgroundColor: theme`colors.green.700`,
  },
  ".MuiSlider-markLabel": {
    color: theme`colors.grey.300`,
    fontSize: "0.9rem",
    fontWeight: 400,
    paddingTop: "0.5rem",
    "&:hover": {
      color: theme`colors.blue.800`,
      fontWeight: 600,
    },
  },
  ".MuiSlider-markLabelActive": {
    fontSize: "0.9rem",
    fontWeight: 600,
    color: theme`colors.blue.800`,
  },
  ".MuiSlider-rail": {
    color: theme`colors.green.700`,
    height: "1px",
    opacity: 1,
  },
  ".MuiSlider-thumb": {
    height: "2rem",
    width: "2rem",
    backgroundColor: theme`colors.green.400`,
    border: "solid 1px #13173D",
    "&:hover": {
      backgroundColor: theme`colors.green.500`,
      boxShadow: "none",
      border: "solid 2px #13173D",
    },
    "&.Mui-focusVisible": {
      backgroundColor: theme`colors.green.500`,
      boxShadow: "none",
      border: "solid 2px #13173D",
    },
  },
  ".MuiSlider-markActive": {
    color: theme`colors.blue.800`,
  },
}));

const YearSlider = ({
  isFromCountryComparison,
  isFromMap,
}: {
  isFromCountryComparison?: boolean;
  isFromMap?: boolean;
}) => {
  const { year, mobileModalDropdown, setFilters, setMobileModalDropdown } =
    useCategoriesClock();

  const handleChange = (_: Event, value: number | number[]) =>
    setFilters({
      value,
      name: "year",
    });

  return (
    <article
      css={[
        tw`w-full md:bg-white pb-8 max-w-[90rem] m-auto`,
        isFromCountryComparison && tw`md:w-[calc(100% - 2rem)] md:m-0 md:mt-4`,
      ]}>
      <article
        tw="flex-col gap-[0.5rem] hidden lg:flex px-4 lg:pt-4"
        css={[isFromCountryComparison && tw`p-0`]}>
        <h3 tw="text-grey-700 leading-[0.938rem] text-sm font-semiBold">
          {t("years")}
        </h3>
        <button
          onClick={() =>
            setMobileModalDropdown({
              value: !mobileModalDropdown.isMobileModalDropdownOpen,
              name: "mobileModalDropdown",
              section: "years",
            })
          }
          tw="flex justify-between items-center text-grey-700 border border-blue-800 rounded-full p-[1rem 0.688rem]">
          {t(year)}
          <img src={arrow} alt="Arrow" />
        </button>
      </article>
      <article
        css={[
          tw`p-[0.5rem 3rem] h-[5rem] lg:hidden bg-white flex justify-between items-center`,
          isFromMap && tw`w-full`,
        ]}>
        <StyledSlider
          track={false}
          getAriaLabel={() => "Year slider marks"}
          getAriaValueText={(value) => value.toString()}
          marks={years}
          min={2024}
          max={2034}
          onChange={handleChange}
          value={Number(year)}
          tw="m-0 p-0"
        />
      </article>
    </article>
  );
};

export default YearSlider;
